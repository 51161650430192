/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

const SEO = ({description, lang, meta, title, strictTitle}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            link
            author
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const titleTemplate = `%s | ${defaultTitle}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={strictTitle ? title : titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'author',
          content: site.siteMetadata.author
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords.join(", ")
        },
        {
          property: `theme-color`,
          content: `#3A7CFF`
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.link
        },
        {
          property: `og:image`,
          content: `${withPrefix("/")}img/growpad-social-asset.png`
        },
        {
          property: `og:type`,
          content: `business.business`
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <html className="has-navbar-fixed-top" lang="en" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale = 1.0,
          maximum-scale=1.0, user-scalable=no"
      />
{/*       <script src={`${withPrefix("/")}js/widget.js`} />
      <script
        type="text/javascript"
        src="https://ind-widget.freshworks.com/widgets/82000001893.js"
        async
        defer
      ></script> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  strictTitle: false
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  strictTitle: PropTypes.bool
}

export default SEO
